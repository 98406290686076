<template>
  <div class="resetPassword_wrapper">
    <p class="title">{{ $t('resetPassword.header') }}</p>
    <div class="resetPassword_box" v-show="isAuthorized">
      <div class="form_box" v-show="formFlag">
        <el-form label-position="top" :model="ruleResetPasswordForm" :rules="rulesResetPassword"
          ref="ruleResetPasswordForm">
          <el-form-item prop="pass">
            <div class="self_label">{{ $t('common.field.newPw') }}</div>
            <el-input type="password" v-model="ruleResetPasswordForm.pass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="checkPass">
            <div class="self_label">{{ $t('common.field.confirmPw') }}</div>
            <el-input type="password" v-model="ruleResetPasswordForm.checkPass" autocomplete="off"></el-input>
          </el-form-item>
          <!-- <p :class="{ isError: isError }">
            {{ $t('common.formValidation.newPwFormat') }}
          </p> -->
          <el-button class="reset_btn" @click.native="submitForm('ruleResetPasswordForm')" data-testid="submit">
            {{ $t('common.button.submit') }}
          </el-button>
        </el-form>
      </div>
      <div class="reset_success" v-show="successFlag">
        <p class="success_info">{{ $t('resetPassword.succ') }}</p>
        <el-button type="primary" @click="goHome" class="go_home_btn">
          {{ $t('common.button.bkToHm') }}
        </el-button>
      </div>
    </div>
    <p v-show="isNotAuthroized" class="no_authroization">{{ $t('resetPassword.failed') }}</p>
  </div>
</template>

<script>
import { rsa } from '@/util/encrypt';
// import passwordValidator from 'password-validator';
import { apiCheckResetPasswordAuthorization, apiSubmitResetMt4AccountPassword } from '@/resource';
import passwordCheckMixin from '@/mixins/passwordCheck';

export default {
  name: 'resetPassword',
  mixins: [passwordCheckMixin],
  data() {
    return {
      isAuthorized: false,
      isNotAuthroized: true,
      formFlag: true,
      successFlag: false,
      // isError: false,
      ruleResetPasswordForm: {
        pass: '',
        checkPass: '',
        token: ''
      },
      // schema: ''
    };
  },
  components: {},
  mounted() {
    const token = this.$route.query.token;
    // this.schema = new passwordValidator();
    // this.schema
    //   .is()
    //   .min(8)
    //   .has()
    //   .uppercase()
    //   .has()
    //   .digits()
    //   .has()
    //   .not()
    //   .spaces();
    this.verifyAuthorization(token);
  },
  methods: {
    verifyAuthorization(token) {
      apiCheckResetPasswordAuthorization({
        token: token
      }).then(resp => {
        if (resp.data.code == 0) {
          this.ruleResetPasswordForm.token = resp.data.data;
          this.isAuthorized = true;
          this.isNotAuthroized = !this.isAuthorized;
        } else {
          this.isAuthorized = false;
          this.$router.push('/login');
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          apiSubmitResetMt4AccountPassword({
            token: this.ruleResetPasswordForm.token,
            newPassword: rsa(this.ruleResetPasswordForm.pass)
          })
            .then(resp => {
              if (resp.data.code == 0 && resp.data.data == true) {
                this.formFlag = false;
                this.successFlag = true;
              }
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    goHome() {
      this.$router.push('/home')
    }
  }
};
</script>
<style lang="scss" scoped>
// @import '@/assets/css/pages/resetProfilePasswordPug.scss';
.resetPassword_wrapper {
  p {
    font-size: 14px;
    color: $text-secondary;
    line-height: 21px;
  }

  .title {
    font-size: 24px;
    color: #09090D;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
  }

  .no_authroization,
  .reset_success {
    text-align: center;
    margin-top: 24px;
  }

  .reset_success {
    display: flex;
    flex-direction: column;
    align-items: center;

    .go_home_btn {
      margin-top: 24px;
    }
  }

  .el-form-item:nth-child(1) {
    margin-top: 24px;
  }

  .self_label {
    font-size: 16px;
    color: $text-primary;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    @include rtl-sass-prop-dual(text-align, left, text-align, right);

    span {
      font-weight: 400;
      color: $danger;
      @include rtl-sass-prop(margin-right, margin-left, 2px);
    }
  }

  .reset_btn {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    border-radius: 8px;
    background: $secondary;
    margin-top: 16px;
  }

}
</style>
